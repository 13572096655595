<template>
    <special-projects-page/>
</template>

<script>
    import SpecialProjectsPage from "@/components/pages/SpecialProjectsPage";

    export default {
        name: "SpecialProjects",
        title: "Proyectos Especiales | Private",
        components: {SpecialProjectsPage}
    }
</script>

<style scoped>

</style>