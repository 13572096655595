<template>
    <v-container fill-height fluid class="pa-0 ma-0 mb-10">
        <v-row class="ma-0 pa-0">
            <v-img :src="require('@/assets/images/evento_4.jpg')"
                   width="100%"
                   height="250"
                   position="bottom"
                   class="mx-0 align-end"
                   transition="slide-x-transition"
                   gradient="to top, rgba(0,0,0,.3), rgba(0,0,0,.5)">
                <v-row align="end" justify="center">
                    <v-col cols="12">
                        <div class="fill-height container--fluid px-5 px-md-16 pb-10">
                            <span class="font-weight-thin text-h5 mx-2 text-md-h4 white--text">
                               PROYECTOS ESPECIALES
                            </span>
                            <v-divider dark class="mt-2"/>
                        </div>
                    </v-col>
                </v-row>
            </v-img>
        </v-row>
        <v-row align="center" class="pt-8 pb-8 px-5 px-md-10" justify="center">
            <v-col v-for="(item, index) in cardItems" :key="index"
                   cols="12"
                   md="6">
                <div class="mx-1 mx-md-5 fill-height fluid">
                    <v-img
                        :src="item.backimageurl"
                        class="rounded-lg elevation-15 align-center"
                        height="400px"
                        transition="scroll-x-transition"
                        width="100%">
                        <div class="fill-height align-center fluid blend-opacity"
                             style="height: 400px" @click="showPageInfo(item.link)">
                            <v-layout
                                align-content-center
                                child-flex
                                column
                                fill-height
                                justify-center
                                wrap>
                                <v-flex align-self-center shrink>
                                        <span
                                            class="white--text font-weight-light pa-2 text-center text-no-wrap text-uppercase">
                                            {{ item.title }}
                                        </span>
                                </v-flex>
                                <v-flex align-self-center shrink>
                                    <v-btn
                                        :to="item.link"
                                        class="ma-1"
                                        plain light
                                        depressed
                                        text>
                                            <span class="white--text text-h4 font-weight-bold mb-3 headline pa-2 text-center text-no-wrap text-uppercase">
                                                {{ item.subtitle }}
                                            </span>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                        </div>
                    </v-img>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "SpecialProjectsPage",
        data: () => ({
            cardItems: [
                {
                    backimageurl: require("@/assets/images/terrenazo-logo.jpg"),
                    title: "Centro de Eventos y Espectáculos",
                    subtitle: "TERRENAZO CALIENTE",
                    link: "/terrenazo",
                    flex: 6,
                },
                {
                    backimageurl: require("@/assets/images/logo-inmobiliaria1.png"),
                    title: "Centro de Eventos y Espectáculos",
                    subtitle: "ROSARITO",
                    link: "/rosarito",
                    flex: 6,
                },
            ],
        }),
        methods: {
            showPageInfo(the_path) {
                this.$router.push({path: the_path});
            }
        }
    };
</script>

<style scoped>
    .backcolor {
        background-color: transparent;
    }

    .blend-opacity {
        opacity: 0.2;
        transition: opacity 0.4s ease-in-out;
        background-color: black;
        background-blend-mode: normal;
    }

    .blend-opacity:hover {
        opacity: 0.5;
        transition: opacity 0.4s ease-in-out;
        background-color: black;
        background-blend-mode: darken;
    }

    .v-btn {
        text-transform: none !important;
    }
</style>
